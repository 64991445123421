<template>
  <div class="ticke-cont">
    <van-radio-group v-model="result" :class="config_secondConfirm?'button-bottom':''">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in coupons"
          :class="'itembk '+(item.description?'':'error-ticket')"
          clickable
          :key="index"
          @click="toggle(index)"
        >
          <template #title>
            <h2 class="van-coupon__amount">
              ￥{{item.price.seatPrice}}
              <span>元</span>
              &nbsp;
              <Logo :corp="item.corp" />
              <Logo v-if="item.price.gpAgreement=='true'" :corp="'公务票'" />
            </h2>
            <span class="silk-ribbon1">
              <span v-if="item.description">合规</span>
              <span v-else>超标</span>
            </span>
            <van-tag type="success" round  plain class="discount" v-if="item.price.ordernowReturn">立减{{item.price.ordernowReturn}}元</van-tag>
          </template>
          <template #label>
            <div class="detail">
              <span>{{item.discount}}{{item.seatClass}}</span>
              <span v-if="item.seatCode">&nbsp;|&nbsp;{{item.seatCode}}舱</span>              
              <span>&nbsp;|&nbsp;{{item.name}}</span>
            </div>
          </template>
          <template #right-icon>
            <van-radio :name="item" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
      <div v-if="config_secondConfirm" style="height:80px;width:100%"></div>
    </van-radio-group>
    <div class="btn-cont">
      <van-button v-if="config_secondConfirm" class="bom-btn" type="info" size="large" @click="ok">确认</van-button>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import { TicketSecondConfirm } from "@/common/config.js";
export default {
  name: "AircraftTicket",
  components: { Logo },
  props: {
    coupons: {},
    showList: {},
    chosenCoupon: {},
  },
  data() {
    return {
      config_secondConfirm: TicketSecondConfirm, // 配置 - 选票二次确认
      result: [],
    };
  },
  mounted() {
    this.check();
  },
  watch: {
    showList: {
      handler: function () {
        this.check();
      },
    },
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      if(!this.config_secondConfirm)this.ok()
    },
    check() {
      this.coupons.forEach((element, i) => {
        element.i = i;
      });
      this.result = this.coupons[this.chosenCoupon];
    },
    ok() {
      this.$emit("change", this.result.i);
    },
  },
};
</script>

<style lang="less" scoped>
@import '../styles/theme.less';
.ticke-cont {
  padding: 0.6rem;
  .button-bottom{
    max-height: calc(90vh - 80px);
  }
  .van-radio-group{
    max-height: calc(90vh - 10px);
    display: block;
    overflow: scroll;
  }
  .btn-cont{
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 10px;
    background-color: #fff;
    width: 100%;
    .bom-btn {
      width: 90%;
      margin: 10px 5%;
    }
  }
  .discount{
    position: absolute;
    top: 6%;
    left: 24%;
  }
}
.van-coupon__amount {
  color: @red;
  display: inline-block;
  margin-bottom: 6px !important;
}
.detail {
  max-width: calc(100vw - 100px);
  border-top: 1px dashed @gray-4;
  padding-top: 6px;
}

// 彩带
.silk-ribbon1 {
  position: absolute;
  top: -9px;
  right: 26px;
}
.silk-ribbon1:after {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 8px solid @gray-3;
}
.silk-ribbon1 span {
  position: relative;
  display: inline-block;
  text-align: center;
  background: @gray-3;
  color: @green;
  font-size: @font-size-xs;
  padding: 9px 0 0;
  border-top-right-radius: 8px;
  width: 60px;
}
.error-ticket {
  .silk-ribbon1 span {
    background: @gray-1;
    color: @red;
  }
  .silk-ribbon1:after {
    border-top: 8px solid @gray-1;
  }
}
</style>
