<template>
  <div>
    <van-checkbox-group v-model="result" :max="seat.number" @change="updateResult">
      <van-cell-group>
        <van-cell v-for="(item,i) in personLists" center :key="i">
          <template #title>
            {{item.username||item.passengerName}}&nbsp;
            <template v-if="showStandard">
              <van-tag plain type="success" v-if="checkCanBuy(item,seat)">合规</van-tag>
              <van-tag plain type="danger" v-if="!checkCanBuy(item,seat)">超标</van-tag>
            </template>
          </template>
          <template #label>
            <van-row @click="openEdit(item,i)" class="list-text">
              <span>
                <van-icon class="usericonfont" class-prefix="icon" name="shengrilijin" />
              </span>
              <span v-show="item.birthday">{{item.birthday | comverTime('YYYY-MM-DD')}}</span>
              <span v-show="!item.birthday" class="red">未填</span>&nbsp;|&nbsp;
              <span :class="item.gender?'':'red'">{{item.gender||'未填'}}</span>&nbsp;|&nbsp;
              <span>
                <van-icon class="usericonfont" class-prefix="icon" name="dianhua" />{{item.tel}}
                <span class="iconfont icon-xiugai edit-tel"></span>
              </span>
            </van-row>
          </template>
          <template #right-icon>
            <van-checkbox v-if="isNormal" :name="item" ref="checkboxes" class="bigcheck" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>

    <van-divider v-if="personLists.length==0" :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      暂无人员信息</van-divider>

    <!-- 人员信息-弹出层 -->
    <van-dialog closeOnClickOverlay v-model="show" title="修改人员信息" show-cancel-button :beforeClose="subimtTel"
      class="editInfo">
      <van-form ref="editTel">
        <div class="gap"></div>
        <van-field v-model="idCard" center clearable name="validatorID" placeholder="请输入证件号码"
          :rules="[{ validator:validatorID, message: '请输入正确内容' }]">
          <template #label>
            <el-select v-model="idType" slot="prepend" placeholder="请选择">
              <el-option label="身份证" value="身份证"></el-option>
              <el-option label="护照" value="护照"></el-option>
            </el-select>
          </template>
        </van-field>
        <van-field v-model="tel" type="digit" label="手机号" name="validator" placeholder="请输入手机号"
          :rules="[{ validator, message: '请输入手机号' }]" />
        <van-field name="radio" label="性别" :rules="[{ required: true, message: '请选择性别' }]">
          <template #input>
            <van-radio-group v-model="gender" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field readonly clickable name="datetimePicker" :value="birthday | comverTime('YYYY-MM-DD')" label="生日"
          placeholder="点击选择时间" @click="showPicker = true" />
      </van-form>
    </van-dialog>

    <!-- 超标填表 -->
    <van-dialog v-model="showExplanation" title="超标说明" show-cancel-button :beforeClose="subimtCompliance">
      <van-form ref="editCompliant">
        <van-field readonly v-model="explanation" label="超标类型" placeholder="请输入超标类型" @click="showReason = true" />
        <van-field :rules="[{ required: true, message: '请填写超标类型' }]" v-model="explanation1" rows="4" autosize label="备注"
          type="textarea" maxlength="100" placeholder="请输入备注" show-word-limit />
      </van-form>
      <div class="text-sm" style="padding:0 1rem;color:#777;display:flex">
        <div style="min-width:60px">
          <small>超标人员：</small>
        </div>
        <div style="padding-bottom:1rem">
          <small v-for="(item,i) in explanationText"
            :key="i">{{item.username}}（{{item.stand.allowBookHighest|maxSeat}}）<br /></small>
        </div>
      </div>
    </van-dialog>

    <van-popup v-model="showReason" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showReason = false" @confirm="onConfirm" />
    </van-popup>

    <div style="z-index:5000">
      <van-popup v-model="showPicker" round position="bottom">
        <van-datetime-picker v-model="pickDate" type="date" title="选择生日" :min-date="minDate" :max-date="maxDate"
          @confirm="birthday=pickDate;showPicker=false" @cancel="showPicker=false" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkSeat } from "@/services/user";
import { REASONS } from "@/common/config";
import moment from "moment";
export default {
  computed: {
    ...mapGetters(["personList", "aircraftOrderChange"]),
    personLists: function() {
      // 如果是改签，用id比对差标加进去   合规检查的方法会用
      if (this.$route.name == "orderAircraftChange") {
        return this.aircraftOrderChange.peoList; // 返回改签人员
      }
      return this.personList; // 返回全部人员
    },
    // 是否为第一次下单
    isNormal: function() {
      return !this.$route.name.includes("Change");
    }
  },
  props: {
    compliant: {
      default: () => {
        return false;
      }
    },
    explanationText: {
      default: () => {
        return [];
      }
    },
    seat: {
      default: () => {
        return [{ seatClass: "经济舱" }];
      }
    },
    max: {
      default: () => {
        return 10;
      }
    },
    showStandard: {
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      showExplanation: false, // 超标窗口开关
      explanation: REASONS[0], // 超标理由
      explanation1: "", // 超标说明
      showReason: false, // 超标理由开关
      columns: REASONS,
      result: [], // 已选人员
      show: false, // 编辑框卡关
      index: 0, // 编辑索引
      idType: "身份证", // 编辑框-证件类型
      idCard: null, // 编辑框-证件号
      tel: null, // 编辑框-电话
      gender: "男", // 编辑框-性别
      birthday: "1996-01-06", // 编辑框-生日
      showPicker: false,
      pickDate: new Date(2000, 0, 1),
      minDate: new Date(1920, 0, 0),
      maxDate: new Date()
    };
  },
  methods: {
    // 身份证验证
    validatorID(val) {
      if (this.idType != "身份证") return val.trim().length > 0;
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
    },
    // 手机号校验
    validator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    // 选择超标理由
    onConfirm(value) {
      this.explanation = value;
      this.showReason = false;
    },
    // 提交申请单，通过的话调用抢座
    subimtCompliance(action, done) {
      if (action === "confirm") {
        this.$refs.editCompliant
          .validate()
          .then(() => {
            this.$emit("explanFinish", this.explanation, this.explanation1);
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        this.$emit("explanFinish");
        done();
      }
    },
    // 合规检查
    checkCanBuy(userRole = 0, seatType = "经济舱") {
      let f = true;
      seatType.forEach(element => {
        f = f && checkSeat(userRole, element, "aircraft");
      });
      return f;
    },
    // 打开信息编辑框，初始化数据
    openEdit(data, index) {
      data.birthday = moment(data.birthday).format("YYYY-MM-DD");
      this.tel = data.tel;
      this.idCard = data.idCard || data.identityNo;
      this.gender = data.gender || "";
      this.birthday = data.birthday || new Date(2000, 0, 1);
      this.pickDate = new Date(data.birthday);
      this.index = index;
      this.show = true;
    },
    // 修改手机号 确认
    subimtTel(action, done) {
      if (action === "confirm") {
        this.$refs.editTel
          .validate()
          .then(() => {
            // 把显示和结果集的手机号都修改一下
            this.personLists[this.index].idType = this.idType;
            this.personLists[this.index].idCard = this.idCard;
            this.personLists[this.index].tel = this.tel;
            this.personLists[this.index].gender = this.gender;
            this.personLists[this.index].birthday = this.birthday;
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    updateResult() {
      this.$emit(
        "update:updateResult",
        this.isNormal ? this.result : this.personLists
      );
    }
  },
  mounted() {
    this.updateResult();
    this.result.push(...this.personLists); // 初始化全选
  },
  filters: {
    maxSeat(data) {
      switch (data) {
        case 4:
          return "最高可订“头等舱”";
        case 3:
          return "最高可订“公务舱”";
        case 2:
          return "最高可订“超值经济舱”";
        case 1:
          return "最高可订“经济舱”";
      }
    }
  },
  watch: {
    max: {
      handler: function() {
        this.result = this.result.slice(0, this.max);
      }
    },
    compliant: {
      handler: function() {
        this.showExplanation = this.compliant;
      }
    }
  }
};
</script>

<style lang="less">
@import "../../../styles/theme.less";
.list-text {
  color: @gray-6;
}
.editInfo {
  .el-select {
    width: 76px;
    .el-input .el-select__caret {
      color: @gray-6;
    }
  }
  .el-input__inner {
    color: @gray-8;
    height: 24px;
    line-height: 24px;
    padding: 0;
    background: rgba(0, 0, 0, 0) !important;
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .el-input__icon {
    line-height: 24px;
  }
}
.el-popper {
  z-index: 2020202 !important;
}
</style>